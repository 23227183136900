/**
 * This map will contain spacing values used in margin, and paddings
*/
@font-face {
  font-family: "keenvil";
  src: url("../assets/fonts/keenvil.eot");
  src: url("../assets/fonts/keenvil.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/keenvil.woff") format("woff"), url("../assets/fonts/keenvil.ttf") format("truetype"), url("../assets/fonts/keenvil.svg#keenvil") format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
  font-family: "keenvil" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^=icon-]:before,
[class*=" icon-"]:before {
  font-family: "keenvil" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-change:before {
  content: "a";
}

.icon-guard:before {
  content: "c";
}

.icon-arrows:before {
  content: "d";
}

.icon-pin:before {
  content: "e";
}

.icon-chat:before {
  content: "f";
}

.icon-home:before {
  content: "g";
}

.icon-close:before {
  content: "h";
}

.icon-menu:before {
  content: "i";
}

.icon-up:before {
  content: "j";
}

.icon-down:before {
  content: "k";
}

.icon-next:before {
  content: "l";
}

.icon-previous:before {
  content: "m";
}

.icon-box:before {
  content: "n";
}

.icon-copy:before {
  content: "o";
}

.icon-quiz2:before {
  content: "p";
}

.icon-check:before {
  content: "q";
}

.icon-plus:before {
  content: "r";
}

.icon-place:before {
  content: "s";
}

.icon-quiz-1:before {
  content: "t";
}

.icon-poll:before {
  content: "u";
}

.icon-calendar:before {
  content: "v";
}

.icon-news:before {
  content: "w";
}

.icon-pluscircle:before {
  content: "x";
}

.icon-bin:before {
  content: "y";
}

.icon-search:before {
  content: "z";
}

.icon-dashboard:before {
  content: "b";
}

.icon-users:before {
  content: "0";
}

.icon-sm {
  font-size: 0.8571428571rem;
}

.icon-m {
  font-size: 1rem;
}

.icon-lg {
  font-size: 1.2857142857rem;
}

.icon-xl {
  font-size: 1.5714285714rem;
}

.icon-xxl {
  font-size: 1.8571428571rem;
}

