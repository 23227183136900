$desktop-xl: 1600px;
$desktop-l: 1000px;
$desktop-m: 860px;
$desktop-s: 480px;

@mixin desktop-s {
  @media (min-width: #{$desktop-s}){
    @content;
  }
}

@mixin desktop-m {
  @media (min-width: #{$desktop-m}) {
    @content;
  }
}

@mixin desktop-l {
  @media (min-width: #{$desktop-l}) {
    @content;
  }
}

@mixin desktop-xl {
  @media (min-width: #{$desktop-xl}) {
    @content;
  }
}