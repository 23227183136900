@import '../../styles/colors.scss';
@import '../../styles/variables.scss';
@import '../../styles/window-sizes.scss';

$itemListColumns-s: 15% 20% 20% 20% 25%;

.main-search-results {
  width: 90%;
  position: absolute;
  top: 39px;
  left: 0;
  right: 0;
  left: spacings(L);
  border-bottom-left-radius: borderRadius(S);
  border-bottom-right-radius: borderRadius(S);
  border: 1px solid color(border);
  border-top: 0;
  background-color: color(white);
  overflow-y: hidden;
  overflow-x: hidden;
  max-height: 600px;
  box-shadow: 0px 34px 64px -41px rgba(0,0,0,1);

  &__header {
    height: 24px;
    padding-left: spacing(M);
    background-color: color(paleGreyThree);
    font-size: textSize(S);
    font-weight: 600;
    line-height: 2.1;
    letter-spacing: normal;
    color: color(darkTypo);
  }

  &__list {
    align-items: center;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    margin: 0;
    padding: 10px 0px 10px 0px ;

    &__itemUser{
      padding: 0 16px;
      width: 100%;
      display: grid;
      grid: 50px / 15% 40% 45%;
      list-style: none;
      align-items: center;
      justify-content: space-around;
      text-decoration: none;
      cursor: pointer;

      &__avatar {
        width: 50px;
        margin-right: spacing(M);
      }

      &__name {
        width: 100%;
        margin-right: spacing(L);
        margin-bottom: 0;
        color: color(darkTypo);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: none;
      }

      &__info {
        color: color(greyTypo);
        margin-bottom: 0;
        text-decoration: none;
        text-overflow: ellipsis;
        display: inline;
      }
        
      &:hover {
        background-color: color(border);
      }

    }

    &__itemUnit{
        display: grid;
        grid-template-columns: $itemListColumns-s;
        grid-template-rows: 40px;
        align-items: center;
        padding: 0 16px;
        padding-right: 0px;

        &:hover {
          background-color: color(border);
        }
        *{margin: 0;}
      

      &__avatar {
        width: 50px;
        margin-right: spacing(M);
      }

      &__name {
        width: auto;
        margin-right: spacing(L);
        margin-bottom: 0;
        color: color(darkTypo);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: none;
      }

      &__info {
        color: color(greyTypo);
        margin-bottom: 0;
        text-decoration: none;
        text-overflow: ellipsis;
        display: inline;
      }
        
      &:hover {
        background-color: color(border);
      }

    }

  }

  &__units-header{
    margin:0;
    padding: 5px 0px 5px 16px;
    display: grid;
    grid-template-columns: $itemListColumns-s;
    grid-row: auto;
    align-items: center;
    border-bottom: 1px solid #ccc;

    p{
      margin: 0;
    }
  }

  

  &__result:hover {
    text-decoration: none;
  }
  
}
