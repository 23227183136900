/* This map will contain references to color codes, indexed by color name
    Color names matches Zeplin's Style Guide
*/
/**
 * This map will contain spacing values used in margin, and paddings
*/
/**
 * This map will contain spacing values used in margin, and paddings
*/ /* This map will contain references to color codes, indexed by color name
    Color names matches Zeplin's Style Guide
*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600");
p, h1, h2, h3, h4, h5, h6, .caption, .tiny {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
p strong, h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong, .caption strong, .tiny strong {
  font-weight: 600;
}

h1 {
  font-size: 1.8571428571rem;
  font-weight: 600;
  color: #4d4d4d;
  margin: 20px 0;
}

h2 {
  font-size: 1.5714285714rem;
}

h3, h4, h5, h6 {
  font-size: 1.2857142857rem;
}

p {
  font-size: 1rem;
}

.caption {
  font-size: 0.8571428571rem;
}

.tiny {
  font-size: 0.7142857143rem;
}

.kv-offcanvas {
  display: grid;
  grid-template-rows: auto;
  min-width: 111px;
  padding: 0;
  list-style: none;
  background-color: #ffffff;
  border-right: 1px solid #dfe3e9;
  transition: 500ms;
}
.kv-offcanvas--expanded {
  min-width: 200px;
  transition: 500ms;
  justify-content: flex-start;
}
.kv-offcanvas--expanded .kv-offcanvas__item {
  justify-content: flex-start;
  padding-left: 30px;
}
.kv-offcanvas--expanded .kv-offcanvas__item span {
  transition: 500ms;
  display: inline;
  position: unset;
  opacity: 100%;
  margin-left: 16px;
}
.kv-offcanvas__item {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 500ms;
  text-decoration: none;
  height: 70px;
  width: auto;
  padding: 0 0 0 6px;
}
.kv-offcanvas__item a {
  margin-left: -6px;
  color: #b0b0b1;
}
.kv-offcanvas__item span {
  text-decoration: none;
  opacity: 0%;
  position: absolute;
}
.kv-offcanvas__item__icon {
  color: #b0b0b1;
  width: 24px;
  height: 24px;
}
.kv-offcanvas__item--active::before {
  content: "";
  height: 61px;
  border-top-right-radius: 100px;
  width: 4px;
  background-color: #2ea2f8;
  position: absolute;
  left: 0;
  border-bottom-right-radius: 100px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.kv-offcanvas__item:hover a, .kv-offcanvas__item--active a {
  color: #2ea2f8;
}
.kv-offcanvas__item:hover__icon, .kv-offcanvas__item--active__icon {
  color: #2ea2f8;
}

