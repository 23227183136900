/* This map will contain references to color codes, indexed by color name
    Color names matches Zeplin's Style Guide
*/
/**
 * This map will contain spacing values used in margin, and paddings
*/
/**
 * This map will contain spacing values used in margin, and paddings
*/ /* This map will contain references to color codes, indexed by color name
    Color names matches Zeplin's Style Guide
*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600");
p, h1, h2, h3, h4, h5, h6, .caption, .tiny {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
p strong, h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong, .caption strong, .tiny strong {
  font-weight: 600;
}

h1 {
  font-size: 1.8571428571rem;
  font-weight: 600;
  color: #4d4d4d;
  margin: 20px 0;
}

h2 {
  font-size: 1.5714285714rem;
}

h3, h4, h5, h6 {
  font-size: 1.2857142857rem;
}

p {
  font-size: 1rem;
}

.caption {
  font-size: 0.8571428571rem;
}

.tiny {
  font-size: 0.7142857143rem;
}

/**
 *  Application wide styles
 */
html,
body,
#root {
  min-height: 100vh;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

.application {
  min-height: 100vh;
  width: 100%;
  background-color: #eff3f6;
  overflow-y: hidden;
  position: relative;
}
.application__container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: min-content auto;
}
.application__container aside {
  background-color: #fff;
  min-height: 100vh;
  max-height: 220px;
  border-right: 1px solid #dfe3e9;
}

.supportToolsIndicator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  color: white;
  font-size: 1.1x rem;
  background-color: rgb(117, 80, 194);
}

