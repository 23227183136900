/* This map will contain references to color codes, indexed by color name
    Color names matches Zeplin's Style Guide
*/
/**
 * This map will contain spacing values used in margin, and paddings
*/
.main-search-results {
  width: 90%;
  position: absolute;
  top: 39px;
  left: 0;
  right: 0;
  left: spacings(L);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid #dfe3e9;
  border-top: 0;
  background-color: #ffffff;
  overflow-y: hidden;
  overflow-x: hidden;
  max-height: 600px;
  box-shadow: 0px 34px 64px -41px rgb(0, 0, 0);
}
.main-search-results__header {
  height: 24px;
  padding-left: 16px;
  background-color: #f0f3f8;
  font-size: 0.8571428571rem;
  font-weight: 600;
  line-height: 2.1;
  letter-spacing: normal;
  color: #4d4d4d;
}
.main-search-results__list {
  align-items: center;
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  padding: 10px 0px 10px 0px;
}
.main-search-results__list__itemUser {
  padding: 0 16px;
  width: 100%;
  display: grid;
  grid: 50px/15% 40% 45%;
  list-style: none;
  align-items: center;
  justify-content: space-around;
  text-decoration: none;
  cursor: pointer;
}
.main-search-results__list__itemUser__avatar {
  width: 50px;
  margin-right: 16px;
}
.main-search-results__list__itemUser__name {
  width: 100%;
  margin-right: 30px;
  margin-bottom: 0;
  color: #4d4d4d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}
.main-search-results__list__itemUser__info {
  color: #7f8fa4;
  margin-bottom: 0;
  text-decoration: none;
  text-overflow: ellipsis;
  display: inline;
}
.main-search-results__list__itemUser:hover {
  background-color: #dfe3e9;
}
.main-search-results__list__itemUnit {
  display: grid;
  grid-template-columns: 15% 20% 20% 20% 25%;
  grid-template-rows: 40px;
  align-items: center;
  padding: 0 16px;
  padding-right: 0px;
}
.main-search-results__list__itemUnit:hover {
  background-color: #dfe3e9;
}
.main-search-results__list__itemUnit * {
  margin: 0;
}
.main-search-results__list__itemUnit__avatar {
  width: 50px;
  margin-right: 16px;
}
.main-search-results__list__itemUnit__name {
  width: auto;
  margin-right: 30px;
  margin-bottom: 0;
  color: #4d4d4d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}
.main-search-results__list__itemUnit__info {
  color: #7f8fa4;
  margin-bottom: 0;
  text-decoration: none;
  text-overflow: ellipsis;
  display: inline;
}
.main-search-results__list__itemUnit:hover {
  background-color: #dfe3e9;
}
.main-search-results__units-header {
  margin: 0;
  padding: 5px 0px 5px 16px;
  display: grid;
  grid-template-columns: 15% 20% 20% 20% 25%;
  grid-row: auto;
  align-items: center;
  border-bottom: 1px solid #ccc;
}
.main-search-results__units-header p {
  margin: 0;
}
.main-search-results__result:hover {
  text-decoration: none;
}

