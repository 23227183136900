@import '../../styles/colors.scss';

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  color: color(darkTypo);
}
