.kv-avatar {
  position: relative;
}
.kv-avatar__image {
  object-fit: cover;
}
.kv-avatar__image-noRadius {
  border-radius: 0 !important;
}
.kv-avatar__image-noRadius_name {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 0px;
  left: 0;
  right: 0;
  margin: 0px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.kv-avatar__image-small {
  height: 40px;
  width: 40px;
  border-radius: 20px;
}
.kv-avatar__image-medium {
  height: 78px;
  width: 78px;
  border-radius: 32px;
}
.kv-avatar__image-large {
  height: 128px;
  width: 128px;
  border-radius: 64px;
}
.kv-avatar__image-x-large {
  height: 256px;
  width: 256px;
  border-radius: 20px;
}
.kv-avatar__image-x-small {
  height: 20px;
  width: 20px;
  border-radius: 10px;
}
.kv-avatar__icon-small {
  font-size: 2.7rem !important;
}
.kv-avatar__icon-medium {
  font-size: 5rem !important;
}
.kv-avatar__icon-large {
  font-size: 7.5rem !important;
}
.kv-avatar__icon-x-small {
  font-size: 1.5rem !important;
}

