/**
 * This map will contain spacing values used in margin, and paddings
*/
$spacings: (
  XS: 6px,
  S: 10px,
  M: 16px,
  L: 30px,
  XL: 40px,
  XXL: 50px
);

@function spacing($key) {
  @if map-has-key($spacings, $key) {
    @return map-get($spacings, $key);
  }

  @warn "Unknown `#{$key}` in $spacings.";
  @return null;
}

$baseTextSize: 14;
$fontFamily: 'Open Sans', sans-serif;
$keenvilFont: 'Keenvil';

$textSizes: (
  XXL: 26,
  XL: 22,
  L: 18,
  M: 14,
  S: 12,
  XS: 10
);

@function textSize($key) {
  @if map-has-key($textSizes, $key) {
    @return #{map-get($textSizes, $key) / $baseTextSize}rem;
  }

  @warn "Unknown `#{$key}` in $textSizes.";
  @return null;
}

@function textSizeInRem($size) {
  @return #{$size / $baseTextSize}rem;
}

$borderRadiuses: (
  S: 4px,
  M: 6px,
  L: 10px
);

@function borderRadius($key) {
  @if map-has-key($borderRadiuses, $key) {
    @return map-get($borderRadiuses, $key);
  }

  @warn "Unknown `#{$key}` in $borderRadiuses.";
  @return null;
}

$zIndexes: (
  modal     : 9000,
  overlay   : 8000,
  dropdown  : 7000,
  sidebar   : 6001,
  header    : 6000,
  footer    : 5000
);

@function zIndex($key) {
  @if map-has-key($zIndexes, $key) {
    @return map-get($zIndexes, $key);
  }

  @warn "Unknown `#{$key}` in $zIndexes.";
  @return null;
}
