@import '../../styles/colors.scss';
@import '../../styles/variables.scss';
@import '../../styles/typography.scss';

$delay:500ms;

.kv-offcanvas {
  display: grid;
  grid-template-rows: auto;

  min-width: 111px;
  padding: 0;
  list-style: none;
  background-color: color(white);
  border-right: 1px solid color(border);

  transition: $delay;

  &--expanded {
    min-width: 200px;
    transition: $delay;
    justify-content: flex-start;
    

    .kv-offcanvas__item {
      justify-content: flex-start;
      padding-left: spacing(L);
      span {
        transition: $delay;
        display: inline;
        position: unset;
        opacity: 100%;
        margin-left: spacing(M);
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $delay;
    text-decoration: none;

    height: 70px;
    width: auto;
    padding: 0 0 0 spacing(XS);

    a {
      margin-left: -#{spacing(XS)};
      color: color(disabled);
    }

    span {
      text-decoration: none;
      opacity: 0%;
      position: absolute;
    }

    &__icon {
      color: color(disabled);
      width: 24px;
      height: 24px;
    }
  }
  &__item--active::before {
    content: "";
    height: 61px;
    border-top-right-radius: 100px;
    width: 4px;
    background-color: color(main);
    position: absolute;
    left: 0;
    border-bottom-right-radius: 100px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &__item:hover, &__item--active {
    a {
      color: color(main);
    }

    &__icon {
      color: color(main);
    }
  }
}