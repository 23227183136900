@import '../../styles/colors.scss';
@import '../../styles/variables.scss';
@import '../../styles/window-sizes.scss';

.main-search-bar {
  width: 100%;
  padding: 0 10px;
  font-weight: 400;

  &__input {
    height: 40px;
    position: relative;
    width: 100%;
    border: 1px solid color(border);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: color(main);

    @include desktop-l(){
      width: 80%;
    }

    @include desktop-xl(){
      width: 60%;
    }


    &__icon {
      align-items: center;
      top: 12px;
      left: 15px;
      position: absolute;
      z-index: 2;
    }

    input{
      position: absolute;
      height: 26px;
      margin: 0;
      top:7px;
      left: 40px;
      right: 10px;
      outline: none;
      padding: 0 2px;
      transition: 200ms;
      border: 0;
      border-bottom: 1px transparent;
      z-index: 100;

      &:focus{
        border-bottom: 1px solid rgba( color(main) , 0.5);
      }
    }
  }

}