.kv-avatar {
  position: relative;

  &__image {
    object-fit: cover;
  
    &-noRadius {
      border-radius: 0 !important;
  
    &_name {
      position: absolute;
      background-color: rgba(#fff,0.8);
      padding: 5px 0px;
      left: 0;
      right: 0;
      margin: 0px;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

  }

    &-small {
      height: 40px;
      width: 40px;

      border-radius: 20px;
    }

    &-medium {
      height: 78px;
      width: 78px;
      border-radius: 32px;
    }

    &-large {
      height: 128px;
      width: 128px;
      border-radius: 64px;
    }

    &-x-large {
      height: 256px;
      width: 256px;

      border-radius: 20px;
    }

    &-x-small {
      height: 20px;
      width: 20px;

      border-radius: 10px;
    }

  }

  &__icon {
   
    &-small {
      font-size: 2.7rem !important;
    }
  
    &-medium {
      font-size: 5rem !important;
    }
  
    &-large {
      font-size: 7.5rem !important;
    }
  
    &-x-small {
      font-size: 1.5rem !important;
    }

  }
}
