/* This map will contain references to color codes, indexed by color name
    Color names matches Zeplin's Style Guide
*/
/**
 * This map will contain spacing values used in margin, and paddings
*/
.kv-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  background-color: #ffffff;
  border-bottom: 1px solid #dfe3e9;
}
.kv-header__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 111px;
  height: 100%;
  border-right: 1px solid #dfe3e9;
}
.kv-header__logo svg {
  fill: #191919;
  color: #191919;
}
.kv-header__search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-right: auto;
  color: #b0b0b1;
  z-index: 6000;
}
.kv-header__community {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  width: fit-content;
  height: 100%;
  border-left: 1px solid #dfe3e9;
  border-right: 1px solid #dfe3e9;
}
.kv-header__community p {
  margin: 0;
  font-size: 1.2857142857rem;
  font-weight: 400;
  width: max-content;
  color: #4d4d4d;
}
.kv-header__community .dropdown {
  width: 100%;
}
.kv-header__community .dropdown-menu {
  width: fit-content;
}
.kv-header__community .dropdown-toggle,
.kv-header__community .open > .dropdown-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-color: #ffffff;
  color: #7f8fa4;
  box-shadow: none;
  text-align: left;
}
.kv-header__community .dropdown-toggle .caret,
.kv-header__community .open > .dropdown-toggle .caret {
  color: #b0b0b1;
}
.kv-header__community .dropdown-toggle:focus,
.kv-header__community .open > .dropdown-toggle:focus {
  border-color: #ffffff;
  background-color: #ffffff;
}
.kv-header__community .dropdown-toggle:hover,
.kv-header__community .open > .dropdown-toggle:hover {
  border-color: #ffffff;
  background-color: #ffffff;
}
.kv-header__community .dropdown-toggle:active,
.kv-header__community .open > .dropdown-toggle:active {
  box-shadow: none;
  background-color: #ffffff;
}
.kv-header__profile {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 111px;
  height: 100%;
  cursor: pointer;
}
.kv-header__profile i, .kv-header__profile img {
  margin-right: 16px;
}
.kv-header__profile i, .kv-header__profile span {
  color: #b0b0b1;
}
.kv-header__profile__logoff {
  cursor: pointer;
}

