@charset "UTF-8";
/* This map will contain references to color codes, indexed by color name
    Color names matches Zeplin's Style Guide
*/
/**
 * This map will contain spacing values used in margin, and paddings
*/
.kv-breadcrumb {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding-left: 30px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
}
.kv-breadcrumb__nav {
  color: #b0b0b1;
  padding-right: 16px;
  cursor: pointer;
}
.kv-breadcrumb__items {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}
.kv-breadcrumb__tutorialsBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100px;
}
.kv-breadcrumb__item {
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
}
.kv-breadcrumb__item:not(:first-child):before {
  content: "›";
  margin: 0 5px;
  line-height: 1em;
  font-size: 20px;
  color: #b0b0b1;
}
.kv-breadcrumb__text {
  color: #b0b0b1;
}
.kv-breadcrumb__text--active {
  color: #2ea2f8;
  pointer-events: none;
  text-decoration: none;
}
.kv-breadcrumb__text--active:hover, .kv-breadcrumb__text--active:visited, .kv-breadcrumb__text--active:focus {
  color: #2ea2f8;
  text-decoration: none;
}

