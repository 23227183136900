@import './variables.scss';
@import './colors.scss';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600');

p, h1, h2, h3, h4, h5, h6, .caption, .tiny {
  font-family: $fontFamily;
  font-weight: 400;
  strong {
    font-weight: 600;
  }
}

h1 {
  font-size: textSize(XXL);
  font-weight: 600;
  color: color(darkTypo);
  margin: 20px 0;
}

h2 {
  font-size: textSize(XL);
}

h3, h4, h5, h6 {
  font-size: textSize(L);
}

p {
  font-size: textSize(M);
}

.caption {
  font-size: textSize(S);
}

.tiny {
  font-size: textSize(XS);
}