@import '../../styles/colors.scss';
@import '../../styles/variables.scss';
@import '../../styles/typography.scss';

/**
 *  Application wide styles
 */

html,
body,
#root {
  min-height: 100vh;

  font-family: $fontFamily;
  font-size: #{$baseTextSize}px;
}

.application {
  min-height: 100vh;
  width: 100%;
  background-color: color(backgroundGeneral);
  overflow-y: hidden;
  position: relative;

  &__container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: min-content auto;

    aside {
      background-color: #fff;
      min-height: 100vh;
      max-height: 220px;
      border-right: 1px solid color(border);
    }
    
  }
}

.supportToolsIndicator{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  color: white;
  font-size: 1.1x rem;
  background-color: color(keenvil);
}
