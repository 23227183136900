@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.kv-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  height: 60px;

  background-color: color(white);
  border-bottom: 1px solid color(border);

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 111px;
    height: 100%;
    border-right: 1px solid color(border);

    svg {
      fill: color(black);
      color: color(black);
    }
  }

  &__search {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 100%;
    margin-right: auto;

    color: color(disabled);
    z-index: zIndex(header);
  }

  &__community {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    width: fit-content;
    height: 100%;
    border-left: 1px solid color(border);
    border-right: 1px solid color(border);

    p {
      margin: 0;
      font-size: textSize(L);
      font-weight: 400;
      width: max-content;
      color: color(darkTypo);
    }

    .dropdown {
      width: 100%;
    }

    .dropdown-menu {
      width: fit-content;
    }

    .dropdown-toggle,
    .open > .dropdown-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;

      border-color: color(white);
      color: color(greyTypo);
      box-shadow: none;
      text-align: left;

      .caret {
        color: color(disabled);
      }

      &:focus {
        border-color: color(white);
        background-color: color(white);
      }

      &:hover {
        border-color: color(white);
        background-color: color(white);
      }

      &:active {
        box-shadow: none;
        background-color: color(white);
      }

    }

  }

  &__profile {
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 111px;
    height: 100%;

    cursor: pointer;

    i, img {
      margin-right: spacing(M);
    }

    i, span {
      color: color(disabled);
    }

    &__logoff {
      cursor: pointer;
    }

  }

}
