@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.kv-breadcrumb {

  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding-left: spacing(L);

  background-color: color(white);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);

  &__nav {
    color: color(disabled);
    padding-right: spacing(M);
    cursor: pointer;
  }

  &__items {
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: color(white);
  }

  &__tutorialsBtn{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100px;
    
  }

  &__item {
    display: inline-block;
    font-family: $fontFamily;
    font-size: textSize(M);

    &:not(:first-child):before {
      content: '\203A';
      margin: 0 5px;
      line-height: 1em;
      font-size: 20px;
      color: color(disabled);
    }
  }

  &__text {
    color: color(disabled);

    &--active {
      color: color(main);
      pointer-events: none;
      text-decoration: none;

      &:hover,
      &:visited,
      &:focus {
        color: color(main);
        text-decoration: none;
      }
    }
  }
}
