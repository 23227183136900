/* This map will contain references to color codes, indexed by color name
    Color names matches Zeplin's Style Guide
*/
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  color: #4d4d4d;
}

