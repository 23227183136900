@charset "UTF-8";
@import 'variables.scss';

@font-face {
  font-family: "keenvil";
  src:url("../assets/fonts/keenvil.eot");
  src:url("../assets/fonts/keenvil.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/keenvil.woff") format("woff"),
    url("../assets/fonts/keenvil.ttf") format("truetype"),
    url("../assets/fonts/keenvil.svg#keenvil") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "keenvil" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "keenvil" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-change:before {
  content: "\61";
}
.icon-guard:before {
  content: "\63";
}
.icon-arrows:before {
  content: "\64";
}
.icon-pin:before {
  content: "\65";
}
.icon-chat:before {
  content: "\66";
}
.icon-home:before {
  content: "\67";
}
.icon-close:before {
  content: "\68";
}
.icon-menu:before {
  content: "\69";
}
.icon-up:before {
  content: "\6a";
}
.icon-down:before {
  content: "\6b";
}
.icon-next:before {
  content: "\6c";
}
.icon-previous:before {
  content: "\6d";
}
.icon-box:before {
  content: "\6e";
}
.icon-copy:before {
  content: "\6f";
}
.icon-quiz2:before {
  content: "\70";
}
.icon-check:before {
  content: "\71";
}
.icon-plus:before {
  content: "\72";
}
.icon-place:before {
  content: "\73";
}
.icon-quiz-1:before {
  content: "\74";
}
.icon-poll:before {
  content: "\75";
}
.icon-calendar:before {
  content: "\76";
}
.icon-news:before {
  content: "\77";
}
.icon-pluscircle:before {
  content: "\78";
}
.icon-bin:before {
  content: "\79";
}
.icon-search:before {
  content: "\7a";
}
.icon-dashboard:before {
  content: "\62";
}
.icon-users:before {
  content: "\30";
}

.icon-sm {
  font-size: textSize(S);
}

.icon-m {
  font-size: textSize(M);
}

.icon-lg {
  font-size: textSize(L);
}

.icon-xl {
  font-size: textSize(XL);
}

.icon-xxl {
  font-size: textSize(XXL);
}