/* This map will contain references to color codes, indexed by color name
    Color names matches Zeplin's Style Guide
*/
/**
 * This map will contain spacing values used in margin, and paddings
*/
.main-search-bar {
  width: 100%;
  padding: 0 10px;
  font-weight: 400;
}
.main-search-bar__input {
  height: 40px;
  position: relative;
  width: 100%;
  border: 1px solid #dfe3e9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #2ea2f8;
}
@media (min-width: 1000px) {
  .main-search-bar__input {
    width: 80%;
  }
}
@media (min-width: 1600px) {
  .main-search-bar__input {
    width: 60%;
  }
}
.main-search-bar__input__icon {
  align-items: center;
  top: 12px;
  left: 15px;
  position: absolute;
  z-index: 2;
}
.main-search-bar__input input {
  position: absolute;
  height: 26px;
  margin: 0;
  top: 7px;
  left: 40px;
  right: 10px;
  outline: none;
  padding: 0 2px;
  transition: 200ms;
  border: 0;
  border-bottom: 1px transparent;
  z-index: 100;
}
.main-search-bar__input input:focus {
  border-bottom: 1px solid rgba(46, 162, 248, 0.5);
}

